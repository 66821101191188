import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

import { handleAuthentication } from '../utils/auth';
import FullPageSpinnerContainer from '../components/SessionCheck/FullPageSpinnerContainer';
import Spinner from '../components/SessionCheck/Spinner';
import { fetchDataAfterAuthentication } from '../state/actions/identityActions';
import actions from '../state/actions';

const propTypes = {
  location: PropTypes.object
};

const defaultProps = {
  location: {}
};

const Callback = ({ location }) => {
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  const callbackRedirectPath = params.get('callbackRedirectPath');
  const sponsorshipCode = params.get('sponsorshipCode');

  useEffect(() => {
    let isMounted = true;

    handleAuthentication()
      .then(async (authResult) => {
        dispatch({
          type: actions.SET_LOGIN_REQUIRED,
          payload: false
        });

        if (isMounted) {
          dispatch(
            fetchDataAfterAuthentication(
              authResult,
              () => {
                if (sponsorshipCode) {
                  navigate(
                    `${callbackRedirectPath}?sponsorshipCode=${sponsorshipCode}`
                  );
                } else {
                  navigate(`${callbackRedirectPath}`);
                }
              },
              true
            )
          );
        }
      })
      .catch((err) => {
        console.error(err);
        navigate('/');
      });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <FullPageSpinnerContainer>
      <Spinner />
    </FullPageSpinnerContainer>
  );
};

Callback.propTypes = propTypes;
Callback.defaultProps = defaultProps;

export default Callback;
